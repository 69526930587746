import '~/styles/custom-slick.css';
import '~/styles/custom-slick-theme.css';

import TestimonialCard from './Card';
import ContentSlideShow from '../ContentSlideShow';
import CustomSliderItem from '../ContentSlideShow/CustomSliderItem';
type Props = {
  content: Queries.AmbassadorCardFragmentFragment[];
  type: string;
  textColor?: string;
};
const TestimonialSlideShow = ({ content, type, textColor }: Props) => {
  return (
    // @ts-ignore
    <ContentSlideShow length={content.length} type={type} loop={true} textColor={textColor}>
      {content.map((item, i) => (
        <CustomSliderItem key={item.id}>
          <TestimonialCard card={item} />
        </CustomSliderItem>
      ))}
    </ContentSlideShow>
  );
};

export default TestimonialSlideShow;
