import PartialStar from './PartialStar';
import StarIcon from '~/assets/images/icons/star.svg';
import { Box, Flex } from 'theme-ui';

const StarRating = ({ averageScore }: { averageScore: number }) => {
  const totalStars = 5;
  const color = '#F3AD18';
  return (
    <Flex
      sx={{
        '--star-size': '1.6rem',
        height: 'var(--star-size)',
      }}
    >
      {Array.from({ length: totalStars }, (_, index) => {
        if (index + 1 <= averageScore) {
          return (
            <Box
              sx={{
                svg: {
                  height: 'var(--star-size)',
                  width: 'var(--star-size)',
                  path: { fill: color, stroke: color },
                },
              }}
              key={index + 'star'}
            >
              <StarIcon />
            </Box>
          );
        }
        if (averageScore % 1 !== 0) {
          return <PartialStar decimal={parseFloat((averageScore % 1).toFixed(1))} key={index + 'star'} />;
        }
        if (index + 1 > averageScore) {
          return (
            <Box
              sx={{
                svg: {
                  height: 'var(--star-size)',
                  width: 'var(--star-size)',
                  path: { stroke: color },
                },
              }}
              key={index + 'star'}
            >
              <StarIcon />
            </Box>
          );
        }
      })}
    </Flex>
  );
};

export default StarRating;
