import React from 'react';
import { Flex } from 'theme-ui';

const CustomSliderItem = (props) => {
  const { children, tabIndex, dragging } = props;

  return (
    <Flex sx={{ height: '100%' }} {...props}>
      {/* Disable any non-active buttons as necessary for accessibility purposes */}
      {React.Children.map(children, (el) => {
        if (React.isValidElement(el)) return React.cloneElement(el, { tabIndex, dragging });
      })}
    </Flex>
  );
};

export default CustomSliderItem;
